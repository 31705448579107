import React, { useContext, useRef } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";

import styles from "./Animation.styles";

const ReactAnimation = loadable(() =>
	import("@micado-digital/react-animation/ReactAnimation")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));

const Animation = () => {
	const css = styles();
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { animationID } = pageData;
	const $wrapper = useRef(null);

	return (
		<div
			className={clsx(css.animationWrapper, "mco-view-component-header-animation-wrapper")}
			ref={$wrapper}
		>
			<div className={clsx(css.animation, "mco-view-component-header-animation")}>
				<ReactAnimation
					api={`${REACT_APP_PATH}/animationV3.json.api`}
					id={animationID}
					mediaFormats={{
						xs: "header-animation-small",
						sm: "header-animation-medium",
						lg: "header-animation"
					}}
					prevIcon={<ReactSVGIcon src="/img/icons/chevron-left.svg" size={48} />}
					nextIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={48} />}
					showArrows
					showBullets
					textComponents={{
						text1: "p",
						text2: "p"
					}}
					webpFallback
				/>
			</div>
			<div className={clsx(css.logos, "mco-view-component-header-animation__logos")}>
				<a href="https://www.skyrunnerworldseries.com" target="_blank" rel="noreferrer">
					<img
						src="/img/merrell-skyrunner.svg"
						height={38}
						alt="Skyrunner World Series"
						width={130}
					/>
				</a>
				<a href="https://www.austria-skyrunner-series.com" target="_blank" rel="noreferrer">
					<img
						src="/img/skyrunner-austria-series.png"
						height={66}
						alt="Skyrunner Austria Series"
						width={69}
					/>
				</a>
			</div>
		</div>
	);
};

export default Animation;
