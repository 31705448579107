import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import { motion } from "framer-motion";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import useSystemLogo from "@micado-digital/react-ui/hooks/useSystemLogo";
import useIsScrolling from "@micado-digital/react-ui/hooks/useIsScrolling";

import styles from "./Logo.styles";

const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));

const Logo = () => {
	const css = styles();
	const [ui, setUi] = useContext(UiContext);
	const { mobileMenuOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { layoutVariant } = pageData;
	const logo = useSystemLogo("logo");
	let { alt, homeLink, src } = logo;
	const isScrolling = useIsScrolling();

	const handleMenuClose = () => {
		if (mobileMenuOpen) {
			setUi(prevState => ({ ...prevState, mobileMenuOpen: false }));
		}
	};

	return (
		<motion.div
			className={clsx(css.logoWrapper, "mco-view-component-header-logo-wrapper", {
				[css.logoWrapperMenuOpen]: mobileMenuOpen,
				"mco-view-component-header-logo-wrapper--menu-open": mobileMenuOpen,
				[css.logoWrapperNoAnimation]:
					layoutVariant === "no-animation" || layoutVariant === "map",
				"mco-view-component-header-logo-wrapper--no-animation":
					layoutVariant === "no-animation" || layoutVariant === "map",
				[css.logoWrapperScrolling]: isScrolling
			})}
			onClick={handleMenuClose}
			initial={{ scale: 0 }}
			animate={{ scale: 1 }}
			transition={{
				type: "spring",
				stiffness: 260,
				delay: 0.6,
				damping: 20
			}}
		>
			<ReactLink to={homeLink}>
				<img
					alt={alt}
					className={clsx(css.logo, "mco-view-component-header-logo")}
					src={src}
					height={50}
					width={46}
				/>
			</ReactLink>
		</motion.div>
	);
};

export default Logo;
