import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import WebProfileContext from "@WebProfileContext";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import ReactMetaData01 from "@micado-digital/react-metadata/ReactMetadata01";
import Locals from "@micado-digital/react-ui/components/Locals";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";
import useIsCurrentLocation from "@micado-digital/react-ui/hooks/useIsCurrentLocation";
import useTheme from "@material-ui/core/styles/useTheme";
import Header from "@Components/views/Header";
import Animation from "@Components/views/Animation";
import Default from "./Default";
import useConsent from "../hooks/useConsent";

import styles from "./Views.styles";

const Breadcrumb = loadable(() => import("@Components/views/Breadcrumb"));
const Footer = loadable(() => import("@Components/views/Footer"));

const ReactTracking = loadable(() => import("@micado-digital/react-tracking/ReactTracking"));

const Tracking = () => {
	const [profileData] = useContext(WebProfileContext);
	const { trackingCodes = [] } = profileData;

	return <ReactTracking codes={trackingCodes} />;
};

const Views = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { authorID, homepage, id, lang, layoutTag, layoutVariant, meta = {} } = pageData;
	const consent = useConsent();
	const css = styles();
	const theme = useTheme();
	const currentLocation = useIsCurrentLocation();
	const location = useLocation();
	const {
		palette: {
			secondary: { dark: secondaryDarkColor }
		}
	} = theme;

	useEffect(() => {
		setTimeout(() => {
			const hash = document.URL.split("#")[1];

			if (hash) {
				// try to find anchor

				setTimeout(() => {
					const $target = document.querySelector(`[data-anchor="${hash}"]`);

					if ($target) {
						$target.scrollIntoView({ behavior: "smooth", block: "start" });
					}
				}, 200);
			}
		}, 500);
	}, [location]);

	if (!lang) return null;

	const {
		canonical: metaCanonical,
		description: metaDescription,
		indexable: metaIndexable,
		image: metaImage,
		keywords: metaKeywords,
		lat: metaLat,
		links: metaLinks,
		lng: metaLng,
		title: metaTitle
	} = meta;

	const hasAnimation = layoutVariant !== "no-animation" && layoutVariant !== "map";

	return (
		<WebProfile>
			<AccountData authorID={authorID}>
				<Locals url={`/locals/${lang || "de"}.json`}>
					<div className={clsx(css.page, "mco-page")}>
						<Header />
						{hasAnimation && <Animation />}
						{layoutTag === "default" && layoutVariant !== "map" && homepage === false && (
							<Breadcrumb layoutVariant={layoutVariant} />
						)}
						{layoutTag === "default" && currentLocation && <Default />}
						<Footer />
						<micado-ial
							key={consent}
							language={lang}
							path={REACT_APP_PATH}
							page-id={id}
						></micado-ial>
						<PageTransition color={secondaryDarkColor} position="top" />
					</div>
					<ReactMetaData01
						canonical={metaCanonical}
						description={metaDescription}
						index={metaIndexable}
						image={metaImage}
						keywords={metaKeywords}
						lat={metaLat}
						lang={lang}
						links={metaLinks}
						lng={metaLng}
						title={metaTitle}
					/>
					<Tracking />
				</Locals>
			</AccountData>
		</WebProfile>
	);
};

export default Views;
