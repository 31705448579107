import React, { useContext } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import UiContext from "@UiContext";
import { Sling } from "hamburger-react";

import styles from "./Hamburger.styles";

const Hamburger = () => {
	const [ui, setUi] = useContext(UiContext);
	const { mobileMenuOpen } = ui;
	const css = styles();

	const handleClick = () => {
		setUi(prevUi => ({ ...prevUi, mobileMenuOpen: !mobileMenuOpen }));
	};

	return (
		<motion.div
			className={clsx(css.hamburger, "mco-view-component-header-hamburger")}
			initial={{ rotate: 180, scale: 0 }}
			animate={{ rotate: 0, scale: 1 }}
			transition={{
				delay: 0.5,
				type: "spring",
				stiffness: 260,
				damping: 20
			}}
		>
			<Sling size={20} toggled={mobileMenuOpen} toggle={handleClick} />
		</motion.div>
	);
};

export default Hamburger;
