import loadable from "@loadable/component";
import clsx from "clsx";

const Button = loadable(() => import("@material-ui/core/Button"));
const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const Container = loadable(() => import("@Components/views/Container"));
const Form = loadable(() => import("@micado-digital/react-form"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactGallery01 = loadable(() => import("@micado-digital/react-gallery/ReactGallery01"));
const ReactGallery02 = loadable(() => import("@micado-digital/react-gallery/ReactGallery02"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ReactImageText03 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText03")
);
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactReferences03 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences03")
);
const ReactSingleImage04 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage04")
);
const ReactSingleTeaser03 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser03")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactLegacy = loadable(() => import("@micado-digital/react-legacy/ReactLegacy"));
const TeaserGroupGrid = loadable(() => import("@Components/Elements/TeaserGroup/Grid"));
// const TeaserGroupSlider = loadable(() => import("@Components/Elements/TeaserGroup/Slider"));
const Facts = loadable(() => import("@Components/Elements/Facts"));
const Logos = loadable(() => import("@Components/Elements/Logos"));
const Sponsors = loadable(() => import("@Components/Elements/Sponsors"));
const HeadlineTextCombo = loadable(() => import("@Components/Elements/HeadlineTextCombo"));
const SocialIcons = loadable(() => import("../views/SocialIcons"));
const TrailDetailFacts = loadable(() => import("@Components/Elements/TrailDetailFacts"));
const TrailDetailBestTime = loadable(() => import("@Components/Elements/TrailDetailBestTime"));
const TrailMap = loadable(() => import("@Components/Elements/TrailMap"));
const HeaderMap = loadable(() => import("@Components/Elements/HeaderMap"));
const Countdown = loadable(() => import("@Components/Elements/Countdown"));
const LinkList = loadable(() => import("@Components/Elements/LinkList"));

const getJSXElement = (element, lang, l, isMobile, layoutVariant) => {
	const { REACT_APP_PATH } = process.env;
	const { anchor, id, tag } = element;

	switch (tag) {
		case "basic-headline": {
			const { text, variant = 0 } = element;

			let config = {};

			switch (variant) {
				case 0: {
					config = {
						component: "h1",
						variants: {
							xs: "headline-medium",
							sm: "headline-large"
						}
					};
					break;
				}
				case 1: {
					config = {
						component: "h2",
						variants: {
							xs: "headline-small",
							sm: "headline-medium"
						}
					};
					break;
				}
				case 2: {
					config = {
						component: "h3",
						variant: "headline-small"
					};
					break;
				}
				case 3: {
					config = {
						component: "h4",
						variant: "title-large"
					};
					break;
				}
				case 4: {
					config = {
						component: "h5",
						variant: "title-medium"
					};
					break;
				}
				case 5: {
					config = {
						component: "h6",
						variant: "title-small"
					};
					break;
				}
				default: {
					break;
				}
			}

			return (
				<Container
					anchor={anchor}
					className={`mco-view-component-container-basic-headline--${variant + 1}`}
					key={id}
					maxWidth={1328}
					tag={tag}
				>
					<ReactHeadline01
						component={config?.component}
						text={text}
						variant={config?.variant}
						variants={config?.variants}
					/>
				</Container>
			);
		}

		case "basic-text": {
			const { text, variant } = element;

			return (
				<Container
					anchor={anchor}
					className={clsx({
						"mco-view-component-container-basic-text-variant-1": variant === 1
					})}
					key={id}
					maxWidth={1328}
					tag={tag}
				>
					<ReactText01
						text={text}
						variant="body-medium"
						ButtonProps={{
							endIcon: <ReactSVGIcon size={24} src="/img/icons/chevron-down.svg" />,
							variant: "text"
						}}
					/>
				</Container>
			);
		}

		case "basic-list": {
			const { textcontent, title, variant } = element;

			return (
				<Container
					anchor={anchor}
					className={clsx({
						"mco-view-component-container-basic-list-variant-1": variant === 1
					})}
					key={id}
					maxWidth={1328}
					tag={tag}
				>
					<ReactList01
						headlineVariants={{ xs: "headline-small" }}
						icon={<ReactSVGIcon color="primary" src="/img/icons/check.svg" size={24} />}
						textcontent={textcontent}
						textVariant="body-medium"
						title={title}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-table": {
			const { addition, title, textcontent } = element;

			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			return (
				<Container anchor={anchor} key={id} maxWidth={1328} tag={tag}>
					<ReactTable01
						addition={addition}
						headlineVariants={{ xs: "headline-small" }}
						items={newTableArray}
						title={title}
					/>
				</Container>
			);
		}

		case "basic-links": {
			const { elements, title } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1328} tag={tag}>
					<ReactReferences03
						elements={elements}
						hasIcon={false}
						headlineVariants={{ xs: "headline-small" }}
						linkVariant="label-medium"
						title={title}
					/>
				</Container>
			);
		}

		case "basic-video": {
			const { media, title, text } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactVideo02
						additionVariant="body-small"
						headlineVariant="headline-large"
						media={media[0]}
						mediaFormats={{
							xs: "video-mobile",
							sm: "video"
						}}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			const { text } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactHTML key={id} html={text} />
				</Container>
			);
		}

		case "basic-download-group": {
			const { children } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1328} tag={tag}>
					{children?.map(item => getJSXElement(item))}
				</Container>
			);
		}

		case "basic-download": {
			const { media, title } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1328} tag={tag}>
					<ReactFile01 media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container anchor={anchor} key={id} maxWidth={1328} tag={tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-imprint": {
			return (
				<Container anchor={anchor} key={id} maxWidth={1328} tag={tag}>
					<ReactLegacy lang={lang} logoIdentifier="logo" />
				</Container>
			);
		}

		case "basic-dropdown-group": {
			const { children } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1328} tag={tag}>
					<ReactDropDown01 option={0} title={element?.children?.[0]?.title}>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleimage": {
			const { addition, media, option2, reference, title, text } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1440} tag={tag} noPadding>
					<ReactSingleImage04
						addition={addition}
						button={
							<Button color="primary" variant="contained">
								{l("teaser.button")}
							</Button>
						}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "singleimage-mobile",
							sm: "singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						option2={option2}
						reference={reference}
						text={text}
						textVariants={{ xs: "body-medium" }}
						title={title}
						headlineVariants={{ xs: "headline-small", md: "headline-medium" }}
					/>
				</Container>
			);
		}

		case "basic-teaser": {
			const { id, teasergroup: { items = [] } = {}, title, variant = 0 } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<TeaserGroupGrid
						items={items}
						lang={lang}
						mediaFormats={{ xs: "teaser-sub" }}
						title={title}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-singleteaser": {
			const { id, teaser, text, title, variant, teaser: { media } = {} } = element;
			const teaserData = {
				id: id,
				link: teaser?.link,
				media: teaser?.media,
				text: text ? text : teaser?.text,
				title: title ? title : teaser?.title
			};

			return (
				<Container anchor={anchor} key={id} tag={tag} background maxWidth="none" noPadding>
					<ReactSingleTeaser03
						buttonColor="primary"
						buttonLabel={l("teaser.button")}
						headlineVariants={{ xs: "headline-small", md: "headline-medium" }}
						teaser={teaserData}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser-mobile",
							sm: "teaser"
						}}
						textVariant="body-medium"
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			const { elements, id, textcontent, variant } = element;

			const title = textcontent?.items?.find(item => item?.name === "valTitle")?.text;
			const text = textcontent?.items?.find(item => item?.name === "valText")?.text;
			const link = textcontent?.items?.find(item => item?.name === "valURL")?.text;
			const media = elements?.find(e => e.media)?.media?.[0];

			const teaserData = {
				id: id,
				link: link,
				media: media,
				text: text,
				title: title
			};

			if (!element || !textcontent) {
				return;
			}

			return (
				<Container anchor={anchor} key={id} tag={tag} background maxWidth="none" noPadding>
					<ReactSingleTeaser03
						buttonColor="primary"
						buttonLabel={l("teaser.button")}
						headlineVariants={{ xs: "headline-small", md: "headline-medium" }}
						teaser={teaserData}
						media={media}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser-mobile",
							sm: "teaser"
						}}
						textVariant="body-medium"
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			const { addition2, media, option, text, title } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag} background maxWidth="none" noPadding>
					<ReactImageText03
						addition={title}
						additionVariant="body-small"
						headlineVariants={{ xs: "headline-small", lg: "headline-medium" }}
						media={media?.[0]}
						mediaFormats={{
							xs: "imagetext-combi-mobile",
							sm: "imagetext-combi"
						}}
						mediaPath={REACT_APP_PATH}
						title={addition2}
						text={text}
						textVariant="body-medium"
						variant={option}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			const { elements, variant } = element;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} maxWidth={1112} tag={tag}>
						<ReactGallery02
							elements={elements}
							navNext="/img/icons/chevron-right-32.svg"
							navPrev="/img/icons/chevron-left-32.svg"
							mediaFormats={{
								xs: "gallery-mobile",
								sm: "gallery-single"
							}}
							overlayMediaFormats={{
								xs: "gallery"
							}}
							showOverlay={true}
							// thumbs="gallery-preview"
						/>
					</Container>
				);
			}

			return (
				<Container anchor={anchor} maxWidth={1328} key={id} tag={tag}>
					<ReactGallery01
						elements={elements}
						mediaFormats={{
							xs: "gallery-preview"
						}}
						overlayMediaFormats={{
							xs: "gallery"
						}}
					/>
				</Container>
			);
		}

		case "basic-form": {
			const { elements } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1328} tag={tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST">
						<Connector formElements={elements} formID={id} />
					</Form>
				</Container>
			);
		}

		case "extension-facts": {
			const { elements, id, textcontent: { items = [] } = {} } = element;

			return (
				<Container key={id} maxWidth={1440} tag={tag} anchor={anchor} noPadding>
					<Facts elements={elements} items={items} lang={lang} />
				</Container>
			);
		}

		case "extension-logos": {
			const { elements, id } = element;

			let selected = [];

			elements?.map(item => {
				const tmp = item?.elements?.filter(item => item?.tag === "valLogo")?.[0];
				const id = tmp?.media[0]?.id;

				selected?.push(id);
				return null;
			});

			if (!selected || selected?.length === 0) {
				return <></>;
			}

			return (
				<Container key={id} maxWidth={1328} tag={tag} anchor={anchor}>
					<Logos lang={lang} selected={selected?.join(",")} />
				</Container>
			);
		}

		case "extension-sponsors": {
			const { id } = element;

			return (
				<Container key={id} maxWidth="none" tag={tag} anchor={anchor} noPadding background>
					<Sponsors lang={lang} />
				</Container>
			);
		}

		case "extension-social": {
			const { id } = element;

			return (
				<Container key={id} maxWidth={1328} tag={tag} anchor={anchor}>
					<SocialIcons />
				</Container>
			);
		}

		case "extension-headline-text-combo": {
			const { id, textcontent: { items = [] } = {} } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag} maxWidth="none" noPadding>
					<HeadlineTextCombo items={items} lang={lang} />
				</Container>
			);
		}

		case "extension-trail-facts": {
			return (
				<Container anchor={anchor} key={id} tag={tag} maxWidth={1328}>
					<TrailDetailFacts />
				</Container>
			);
		}

		case "extension-trail-besttime": {
			return (
				<Container anchor={anchor} key={id} tag={tag} maxWidth={1328}>
					<TrailDetailBestTime lang={lang} />
				</Container>
			);
		}

		case "extension-trail-map": {
			const { elements, variant } = element;

			// map only

			if (variant === 1) {
				return <HeaderMap elements={elements} key={id} layoutVariant={layoutVariant} />;
			}

			return (
				<Container anchor={anchor} key={id} tag={tag} maxWidth="none">
					<TrailMap elements={elements} lang={lang} />
				</Container>
			);
		}

		case "extension-countdown": {
			const { id, textcontent: { items = [] } = {} } = element;

			return <Countdown items={items} key={id} lang={lang} tag={tag} />;
		}

		case "extension-trail": {
			const { variant } = element;

			if (variant === 2) {
				return (
					<Container anchor={anchor} key={id} maxWidth={1328} tag={tag}>
						<LinkList element={element} lang={lang} />
					</Container>
				);
			}

			return null;
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
