import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	elements: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(10),
		scrollMarginTop: 72 + "px",
		position: "relative",
		zIndex: 1,
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(9)
		},
		"& > *:last-child:not(.mco-collapsing)": {
			marginBottom: theme.spacing(9)
		},
		"& .mco-view-component-container--has-background:last-child": {
			marginBottom: 0 + "px !important"
		}
	},
	noanimation: {
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(14)
		}
	}
}));

export default styles;
