import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	logoWrapper: {
		transition: "150ms margin ease-out",
		[theme.breakpoints.up("sm")]: {
			margin: "124px 0 0 0",
			"& img": {
				height: 163,
				width: 150
			}
		}
	},
	logoWrapperMenuOpen: {
		[theme.breakpoints.up(1100)]: {
			margin: "124px 0 0 0 !important",
			"& .mco-view-component-header-logo": {
				height: "auto",
				width: 150
			}
		}
	},
	logoWrapperNoAnimation: {
		marginTop: 0,
		"& img": {
			height: 50,
			width: 46
		}
	},
	logoWrapperScrolling: {
		[theme.breakpoints.up("sm")]: {
			marginTop: 0,
			"& img": {
				height: 50,
				width: 46
			}
		}
	},
	logo: {
		display: "block"
	}
}));

export default styles;
