export const theme = {
	palette: {
		primary: {
			light: "#ffe6a6",
			main: "#ffb800",
			dark: "#cc9300"
		},
		secondary: {
			light: "#0b6da8",
			main: "#015181",
			dark: "#00304c"
		},
		background: {
			default: "#ffffff",
			header: "#00304c",
			menu: "#015181",
			footer: "#00304c",
			light: "#eef8ff",
			dark: "#00304c"
		},
		text: {
			primary: "#333333",
			secondary: "#666666",
			disabled: "#bfbfbf",
			hint: "#bfbfbf"
		},
		grey: {
			50: "#f8f7f7",
			100: "#e6e5e5",
			200: "#d2d0d1",
			300: "#b4b1b2",
			400: "#9b9798",
			500: "#827d7e",
			600: "#686465",
			700: "#4e4b4c",
			800: "#343232",
			900: "#1a1919",
			A100: "#d2d0d1",
			A200: "#b4b1b2",
			A400: "#343232",
			A700: "#686465"
		},
		common: {
			white: "#ffffff",
			black: "#000000"
		},
		info: {
			light: "#6fd2f1",
			main: "#0abaf0",
			dark: "#0785ab"
		},
		success: {
			light: "#a1d973",
			main: "#52b202",
			dark: "#357401"
		},
		warning: {
			light: "#ffdba8",
			main: "#ff9705",
			dark: "#9f5f04"
		},
		error: {
			light: "#f08080",
			main: "#d50b0b",
			dark: "#8b0101"
		},
		divider: "#cccccc",
		shape: {
			borderRadius: ""
		}
	},
	typography: {
		"display-large": {
			fontFamily: "Aracne Ultra Condensed",
			fontWeight: 400,
			fontSize: 120,
			letterSpacing: 0,
			lineHeight: "102px"
		},
		"display-medium": {
			fontFamily: "Aracne Ultra Condensed",
			fontWeight: 300,
			fontSize: 120,
			letterSpacing: 0,
			lineHeight: "102px"
		},
		"display-small": {
			fontFamily: "Aracne Ultra Condensed",
			fontWeight: 400,
			fontSize: 95,
			letterSpacing: 0,
			lineHeight: "81px"
		},
		"headline-large": {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 64,
			letterSpacing: 0,
			lineHeight: "71px"
		},
		"headline-medium": {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 56,
			letterSpacing: 0,
			lineHeight: "62px"
		},
		"headline-small": {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 40,
			letterSpacing: 0,
			lineHeight: "44px"
		},
		"title-large": {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 32,
			letterSpacing: 0,
			lineHeight: "36px"
		},
		"title-medium": {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 28,
			letterSpacing: 0,
			lineHeight: "31px"
		},
		"title-small": {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 22,
			letterSpacing: 0,
			lineHeight: "25px"
		},
		"body-large": {
			fontFamily: "Roboto",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "36px"
		},
		"body-medium": {
			fontFamily: "Roboto",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: "29px"
		},
		"body-small": {
			fontFamily: "Roboto",
			fontWeight: 400,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: "23px"
		},
		"label-large": {
			fontFamily: "Roboto",
			fontWeight: 500,
			fontSize: 24,
			letterSpacing: 0.24,
			lineHeight: "24px"
		},
		"label-medium": {
			fontFamily: "Roboto",
			fontWeight: 500,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "20px"
		},
		"label-small": {
			fontFamily: "Roboto",
			fontWeight: 500,
			fontSize: 18,
			letterSpacing: 0.54,
			lineHeight: "18px"
		},
		h1: {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 64,
			letterSpacing: 0,
			lineHeight: "71px"
		},
		h2: {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 56,
			letterSpacing: 0,
			lineHeight: "62px"
		},
		h3: {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 40,
			letterSpacing: 0,
			lineHeight: "44px"
		},
		h4: {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 32,
			letterSpacing: 0,
			lineHeight: "36px"
		},
		h5: {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 28,
			letterSpacing: 0,
			lineHeight: "31px"
		},
		h6: {
			fontFamily: "Pragati Narrow",
			fontWeight: 700,
			fontSize: 22,
			letterSpacing: 0,
			lineHeight: "25px"
		},
		subtitle1: {
			fontFamily: "Roboto",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "36px"
		},
		subtitle2: {
			fontFamily: "Roboto",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: "29px"
		},
		overline: {
			fontFamily: "Roboto",
			fontWeight: 400,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: "23px"
		},
		"button-large": {
			fontFamily: "Roboto",
			fontWeight: 500,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "20px"
		},
		"button-medium": {
			fontFamily: "Roboto",
			fontWeight: 500,
			fontSize: 16,
			letterSpacing: 0.16,
			lineHeight: "21px"
		},
		"button-small": {
			fontFamily: "Roboto",
			fontWeight: 500,
			fontSize: 14,
			letterSpacing: 0.42,
			lineHeight: "19px"
		}
	},
	shape: {
		borderRadius: 8
	},
	overrides: {
		MuiButton: {
			root: {
				boxShadow: "none !important",
				fontSize: 20,
				fontWeight: 500,
				minHeight: 48,
				paddingLeft: 16,
				paddingRight: 16,
				textTransform: "unset !important"
			},
			sizeLarge: {
				fontSize: 24,
				fontWeight: 500,
				minHeight: 56,
				paddingLeft: 20,
				paddingRight: 20
			},
			sizeSmall: {
				fontSize: 18,
				fontWeight: 500,
				minHeight: 40
			},
			contained: {
				color: "#FFFFFF !important",
				"& .MuiButton-label": {
					textShadow: "0 1px 2px rgb(0 0 0 / 24%) !important"
				}
			}
		}
	},
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiButtonGroup: {
			disableElevation: true
		},
		MuiPagination: {
			shape: "rounded"
		}
	}
};
