import React, { useContext } from "react";
import loadable from "@loadable/component";
import clsx from "clsx";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import Logo from "./Logo";
import Hamburger from "./Hamburger";
import useIsScrolling from "@micado-digital/react-ui/hooks/useIsScrolling";

import styles from "./Header.styles";

const Container = loadable(() => import("@Components/views/Container"));
const Icons = loadable(() => import("./Icons"));
const MobileMenu = loadable(() => import("./MobileMenu"));

const Header = () => {
	const [ui] = useContext(UiContext);
	const { mobileMenuOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { lang, layoutVariant } = pageData;
	const isScrolling = useIsScrolling();

	const hasAnimation =
		layoutVariant === "no-animation" || layoutVariant === "map" ? false : true;
	const css = styles({ hasAnimation, isScrolling });

	return (
		<div
			className={clsx(css.header, "mco-view-component-header", {
				[css.mobileMenuOpen]: mobileMenuOpen,
				"mco-view-component-header--mobile-menu-open": mobileMenuOpen
			})}
		>
			<div className={clsx(css.top, "mco-view-component-header__top")}>
				<Container
					className={clsx(css.container, "mco-view-component-header__container")}
					maxWidth="none"
				>
					<Logo />
					<Icons lang={lang} />
					<Hamburger />
					{mobileMenuOpen && <MobileMenu />}
				</Container>
			</div>
		</div>
	);
};

export default Header;
