import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	animationWrapper: {
		height: "calc(100vh - 40px)",
		position: "relative"
	},
	animation: {
		height: "100%",
		margin: "0 auto",
		position: "relative",
		"& .mco-animation-01": {
			overflow: "visible",
			"&:after": {
				backgroundImage: "url('/img/animation-decoration-mobile.svg')",
				backgroundSize: "cover",
				bakcgroundPosition: "top",
				bottom: 0,
				content: "''",
				height: 80,
				left: 0,
				position: "absolute",
				pointerEvents: "none",
				zIndex: 1,
				width: "100%",
				[theme.breakpoints.up("sm")]: {
					backgroundImage: "url('/img/animation-decoration.svg')"
				},
				[theme.breakpoints.up("xl")]: {
					backgroundImage: "url('/img/animation-decoration-xl.svg')"
				}
			}
		},
		"& .mco-animation-01__slider": {
			overflow: "hidden",
			"& > div": {
				"&:after": {
					background: "rgba(0, 0, 0, 0.2)",
					bottom: 0,
					content: "''",
					height: "100%",
					left: 0,
					position: "absolute",
					pointerEvents: "none",
					zIndex: 2,
					width: "100%"
				}
			}
		},
		"& .mco-animation-01__texts": {
			color: theme.palette.common.white,
			padding: theme.spacing(5),
			textAlign: "center",
			transform: "rotate(-3deg)",
			zIndex: 3,
			[theme.breakpoints.up("md")]: {
				right: theme.spacing(4)
			},
			[theme.breakpoints.up(1400)]: {
				right: "auto",
				transform: "rotate(-3deg) translate(440px, -40px)"
			}
		},
		"& .mco-animation-01__text--1": {
			fontFamily: "'Aracne Ultra Condensed'",
			fontSize: 90,
			fontWeight: 700,
			lineHeight: 0.846153846153846,
			textShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
			[theme.breakpoints.up("sm")]: {
				fontSize: 120
			}
		},
		"& .mco-animation-01__text--2": {
			fontFamily: "'Aracne Ultra Condensed'",
			fontSize: 72,
			fontWeight: 300,
			lineHeight: 0.847222222222222,
			marginTop: theme.spacing(1),
			textShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
			[theme.breakpoints.up("sm")]: {
				fontSize: 95
			}
		},
		"& .mco-animation-01__bullets": {
			bottom: theme.spacing(-3),
			"& .mco-animation-01__bullet": {
				background: theme.palette.secondary.main,
				height: theme.spacing(1),
				width: theme.spacing(1)
			}
		}
	},
	logos: {
		alignItems: "center",
		background: "#ffffff",
		bottom: 40,
		display: "flex",
		gap: theme.spacing(2),
		height: 108,
		justifyContent: "center",
		left: "50%",
		padding: theme.spacing(0, 2),
		position: "absolute",
		transform: "translate(-50%, 0)",
		zIndex: 1,
		"& a": {
			display: "block"
		},
		"& img": {
			display: "block"
		},
		[theme.breakpoints.up("md")]: {
			left: "auto",
			right: 0,
			transform: "none"
		}
	}
}));
export default styles;
