import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	hamburger: {
		color: theme.palette.common.white,
		margin: theme.spacing(0, -2, 0, -0.5),
		position: "relative",
		[theme.breakpoints.up(374)]: {
			marginLeft: 0
		},
		[theme.breakpoints.up("sm")]: {
			margin: 0
		},
		"&:after": {
			background: "rgba(0,48,76,0.9)",
			borderRadius: 4,
			content: "''",
			height: 48,
			left: 0,
			opacity: 0,
			position: "absolute",
			transform: "scale(1.5)",
			top: 0,
			visibility: "hidden",
			width: 48,
			zIndex: -1
		},
		"&:hover:after": {
			opacity: 1,
			transform: "scale(1)",
			transition: "opacity .2s linear, transform .2s ease-out",
			visibility: "visible"
		}
	}
}));

export default styles;
