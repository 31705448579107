import makeStyles from "@material-ui/styles/makeStyles";

const styles = ({ hasAnimation, isScrolling }) => {
	const hasBackground = isScrolling || !hasAnimation;
	const height = 72;

	return makeStyles(theme => ({
		header: {
			background: hasBackground ? theme.palette.background.header : "transparent",
			height: height,
			left: 0,
			position: "fixed",
			right: 0,
			top: 0,
			transition: "250ms background ease-out",
			userSelect: "none",
			zIndex: theme.zIndex.modal - 1
		},
		mobileMenuOpen: {
			background: "none !important"
		},
		top: {
			height: "100%"
		},
		container: {
			alignItems: "center",
			display: "flex",
			gap: theme.spacing(1),
			height: "100%",
			overflow: "initial !important",
			padding: theme.spacing(0, 3),
			position: "relative"
		}
	}))();
};

export default styles;
