import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import groupFollowingElementsByTag from "@micado-digital/react-ui/utils/groupFollowingElementsByTag";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";
import getJSXElement from "./getJSXElement";

import styles from "./Elements.styles";

const Elements = ({ parentElements }) => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { elements, lang, layoutVariant } = pageData;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	let parsedElements = parentElements ? parentElements : elements;

	// group downloads

	parsedElements = groupFollowingElementsByTag(
		parsedElements,
		"basic-download-group",
		"basic-download"
	);

	// group dropdowns

	parsedElements = groupFollowingElementsByTag(
		parsedElements,
		"basic-dropdown-group",
		["basic-dropdown", "*"],
		"basic-dropdown",
		"basic-dropdown"
	);

	// group wrapper background

	parsedElements = groupFollowingElementsByTag(
		parsedElements,
		"extension-wrapper-background-group",
		["extension-wrapper-background", "*"],
		"extension-wrapper-background",
		"extension-wrapper-background"
	);

	return (
		<div
			className={clsx(css.elements, "mco-elements", {
				[css.noanimation]: layoutVariant === "no-animation"
			})}
		>
			{parsedElements?.map(element => {
				return getJSXElement(element, lang, l, isMobile, layoutVariant);
			})}
		</div>
	);
};

export default Elements;
